<template>
  <div>
    <Nav></Nav>
    <b-container fluid>
      <b-row>
        <b-col class="text-left">
          <b>Movimiento ID: {{ actualCompra.id }}</b>
          <h1>{{ actualCompra.picking_name }}</h1>
        </b-col>
      </b-row>
      <b-row class="text-left">
        <b-col>
          <b-button :disabled:="lockButtons" @click="sendAction('revert')"
            size="sm mr-3" variant="warning" class="ml-2 mb-2">
            Revertir
          </b-button>
          <b-button :disabled:="lockButtons" v-show="actualCompra.state == 'draft'" @click="sendAction('confirm')"
            size="sm mr-3" variant="success" class="ml-2 mb-2">
            Confirmar
          </b-button>
          <b-button :disabled:="lockButtons" v-show="actualCompra.state == 'confirmed'" @click="sendAction('assign')"
            size="sm mr-3" variant="success" class="ml-2 mb-2">
            Asignar
          </b-button>
          <b-button :disabled:="lockButtons" v-show="actualCompra.state == 'assigned'" @click="sendAction('done')"
            size="sm mr-3" variant="success" class="ml-2 mb-2">
            Terminar
          </b-button>
          <b-button :disabled="lockButtons" v-show="actualCompra.state != 'cancel'" @click="sendAction('cancel')"
            size="sm mr-3" variant="danger" class="ml-2 mb-2">
            Cancelar
          </b-button>
        </b-col>
        <b-col lg="3" cols="12">
          <progress-steps :steps="processSteps" :currentStep="actualCompra.state"></progress-steps>
        </b-col>
      </b-row>
      <b-card class="my-2 p-1">
        <b-row>
          <b-col lg="6" md="12">
            <table class="table table-borderless text-left table-sm">
              <tbody>

                <tr>
                  <td><b>Tipo de movimiento:</b></td>
                  <td>{{ actualCompra.picking_type.name }}</td>
                </tr>
                <tr>
                  <td><b>Desde:</b></td>
                  <td>{{ actualCompra.src_location.complete_name }}</td>
                </tr>
                <tr>
                  <td><b>Hacia:</b></td>
                  <td>{{ actualCompra.dst_location.complete_name }}</td>
                </tr>
                <tr>
                  <td><b>Status:</b></td>
                  <td><b-badge :variant="statusBadge(actualCompra.state).variant">{{
                    statusBadge(actualCompra.state).text }}</b-badge></td>
                </tr>

              </tbody>
            </table>
          </b-col>
          <b-col lg="6" md="12">
            <table class="table table-borderless text-left table-sm">
              <tbody>
                <tr>
                  <td><b>Fecha de Creación:</b></td>
                  <td>{{ actualCompra.create_date }}</td>
                </tr>
                <tr>
                  <td><b>Fecha planeada:</b></td>
                  <td>{{ actualCompra.scheduled_date }}</td>
                </tr>
                <tr>
                  <td><b>Fecha Límite:</b></td>
                  <td>{{ actualCompra.date_deadline }}</td>
                </tr>
                <tr>
                  <td><b>Notas:</b></td>
                  <td>{{ actualCompra.note }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Buscar:">
              <b-form-input type="search" v-model="search" @keyup="page = 1" debounce="500"></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-table ref="selectableTable" selectable @row-selected="onRowSelected" :filter="search" small striped hover
              :items="actualCompra.stock_moves" :fields="movesLabels" responsive>
              <template #cell(buttons)="data">
                <b-icon-pencil-fill @click="getMoveItem(data.item.id)" size="sm" variant="success"
                  class="mx-2"></b-icon-pencil-fill>
                <!-- <b-button @click="getMoveItem(data.item.id)" size="sm" variant="success" class="mr-1 mb-1">
                  <b-icon-pencil-fill></b-icon-pencil-fill>
                </b-button>
                <b-button @click="deleteMoveItems(data.item.id)" size="sm" variant="danger" class="mr-1 mb-1">
                  <b-icon-trash-fill></b-icon-trash-fill>
                </b-button> -->
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="text-left my-2">
          <b-col>
            <b-button @click="$bvModal.show('modal-newProduct');" v-shortkey="['ctrl', 'n']"
              @shortkey="$bvModal.show('modal-newProduct')" size="sm mr-3">Agregar Producto [ctrl+n]</b-button>
          </b-col>
        </b-row>
      </b-card>
    </b-container>
    <b-modal id="modal-newProduct" @shown="moveModal" @close="emptyNewItem" :hide-footer="true" size="xl"
      title="Agregar Producto">
      <b-row>
        <b-col lg="12" cols="12">
          <b-form-group label="Producto" description="Selecciona el producto a agregar">
            <vu-select ref="supplySelect" @input="selectedProduct" v-model.number="newItem.supply_id"
              :options="supplies" :reduce="(x) => x.id" label="name"></vu-select>
          </b-form-group>
        </b-col>
        <b-col v-show="newItem.supply_id > 0" cols="12">
          Inventario Actual: {{ actualStockQuant.quantity }} <br>
          Inventario Reservado: {{ actualStockQuant.reserved_quantity }} <br>
          Despues del movimiento: {{ actualStockQuant.quantity - newItem.product_qty }}<br><br>
        </b-col>
        <b-col v-show="newItem.supply_id > 0" cols="10" md="11" lg="5">
          <b-form-group label="Cantidad" description="Cantidad a Solicitar">
            <b-form-input v-on:keydown.enter="createNewItem" ref="supplyQty" :min="0"
              v-model.number="newItem.product_qty" type="number" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2" md="1" lg="1">
          <b-form-group label="UD">
            <h2><b-badge variant="success">{{ selectedSupply.unit }}</b-badge></h2>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-icon-trash-fill @click="deleteMoveItems(data.item.id)" size="sm" variant="danger"
            class="mx-2"></b-icon-trash-fill>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-3">
          <b-button class="mr-3" @click="createNewItem" variant="success">Guardar</b-button>
          <b-button class="mr-3" @click="$bvModal.hide('modal-newProduct'); emptyNewItem();cleanSelectedSupply()"
            variant="danger">Cancelar</b-button>
        </b-col>
      </b-row>
    </b-modal>


  </div>
</template>
<style>
.table-borderless>tbody>tr>td,
.table-borderless>tbody>tr>th,
.table-borderless>tfoot>tr>td,
.table-borderless>tfoot>tr>th,
.table-borderless>thead>tr>td,
.table-borderless>thead>tr>th {
  border: none;

}
</style>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import ProgressSteps from "../components/ProgressSteps.vue";
export default {
  name: "Movimiento",
  components: {
    Nav, ProgressSteps
  },

  data() {
    return {
      lockButtons: false,
      movesLabels: [
        { key: "id", label: "ID" },
        { key: "supply.code", label: "Codigo", sortable: true },
        { key: "supply.name", label: "Nombre", sortable: true },
        { key: "product_qty", label: "Cantidad", sortable: true },
        { key: "supply.unit", label: "Unidad", sortable: true },
        { key: "quantity", label: "Cantidad Hecha", sortable: true },
        { key: "buttons", label: "" },
      ],
      processSteps: [
        { name: "draft", label: "Borrador" },
        { name: "confirmed", label: "Confirmado" },
        { name: "assigned", label: "Asignado" },
        { name: "done", label: "Hecho" },
        { name: "cancel", label: "Cancelado" },
      ],
      search: null,
      selectedShoppingList: null,
      shoppingListItems: [],
      shoppingListItemList: [],
      shoppingLists: [],
      supplies: [],
      selectedSupply:{
        unit:null
      },
      actualCompra: {
        id: null,
        location_id: null,
        location_dest_id: null,
        picking_name: null,
        picking_type_id: null,
        company_id: null,
        user_id: null,
        move_type: null,
        state: "",
        note: null,
        printed: false,
        is_locked: false,
        scheduled_date: null,
        date_deadline: null,
        date_done: null,
        create_date: null,
        provider_id: null,
        purchase_order_id: null,
        stock_moves: [],
        picking_type: {
          name: null,

        },
        src_location: {
          complete_name: null,

        },
        dst_location: {
          complete_name: null,

        },
      },
      actualStockQuant: {
        quantity: null,
        reserved_quantity: null
      },
      actualCompraItems: [],
      newItem: {
        id: null,
        supply_id: null,
        product_qty: 1,
      }
    };
  },

  computed: {

  },
  watch: {},
  mounted() {
    if (this.$route.params.id > 0) {
      this.actualCompra.id = this.$route.params.id;
      this.getMovimiento()
      // this.getCompraItems()
      this.getSupplies()
      // this.emptyNewItem()
      // this.getShoppingLists()
    }
  },
  created() {

  },
  methods: {
    onRowSelected(item) {
      console.log(item)
      if (item.length > 0) {
        this.getMoveItem(item[0].id)
      }
      this.$refs.selectableTable.clearSelected()
    },
    moveModal() {
      if (this.newItem.id > 0) {
        this.$refs.supplyQty.$el.focus()
      } else {
        this.$refs.supplySelect.$refs.search.focus()
      }
    },
    statusBadge(status) {
      let rtn = { variant: "", text: status }
      switch (status) {
        case "draft":
          rtn.variant = "warning"
          rtn.text = "Borrador"
          break;
        case "confirmed":
          rtn.variant = "primary"
          rtn.text = "Confirmado"
          break;
        case "assigned":
          rtn.variant = "primary"
          rtn.text = "Asignado"
          break;
        case "done":
          rtn.variant = "success"
          rtn.text = "Hecho"
          break;
        case "canceled":
          rtn.variant = "danger"
          rtn.text = "Cancelado"
          break;
      }
      return rtn
    },
    selectedProduct(elm) {
      console.log(elm)
      let that = this
      console.log("focusing")
      console.log("focus end")
      axios.get("/stock/location/" + this.actualCompra.location_id + "/supply/" + this.newItem.supply_id + "/quantities")
        .then(function (res) {
          that.actualStockQuant = res.data
          that.$refs.supplyQty.$el.focus()
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    sendAction(action) {
      if(this.lockButtons){
        return
      }
      let text = "Seguro que quieres cambiar de status a " + action
      if (action == "cancel") {
        text = "Seguro que quieres cancelar este movimiento. Esta acción es irreversible."
      }
      this.$bvModal.msgBoxConfirm(text, {
        title: 'Confirmacion',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'Si',
        cancelTitle: "No",
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then(value => {
          if (value) {
            this.lockButtons = true
            let that = this
            axios.post('/stockpicking/' + this.actualCompra.id + '/' + action)
              .then(function (res) {
                that.getMovimiento()
                that.makeToast("success", "Enviado", res.data)
                that.lockButtons = false
              })
              .catch(function (error) {
                that.makeToast("danger", "Error", error.response.data);
                that.lockButtons = false
              });
          }

        })
        .catch(err => {
          console.log(err)
        })

    },
    emptyNewItem() {
      this.actualStockQuant = {
        quantity: null,
        reserved_quantity: null
      }
      this.newItem = {
        id: null,
        supply_id: null,
        product_qty: null
      }
    },
    getSupplies() {
      let that = this
      axios.get("/supplies")
        .then(function (res) {
          that.supplies = res.data.map(function (el) {
            el.name = el.code + " - " + el.name
            return el
          })
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getMovimiento() {
      let that = this
      axios.get("/stockpicking/" + this.actualCompra.id)
        .then(function (res) {
          that.actualCompra = res.data
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    deleteMoveItems(id) {
      let that = this
      axios.delete("/stockpicking/" + this.actualCompra.id + "/move/" + id)
        .then(function () {
          that.getCompraItems()
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getMoveItem(id) {
      let that = this
      axios.get("/stockpicking/" + this.actualCompra.id + "/move/" + id)
        .then(function (res) {
          that.newItem = res.data
          that.cleanSelectedSupply()
          that.selectedSupply=that.supplies.find(x=>x.id==that.newItem.supply_id)
          that.$bvModal.show('modal-newProduct')
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    cleanSelectedSupply(){
      this.selectedSupply={
        unit:null
      }
    },
    getSucursal() {
      let that = this;
      axios
        .get("/restaurant/id")
        .then(function (response) {
          that.actualSucursal = response.data;
          that.getCompras()
        })
        .catch(function (err) {
          console.log(err);
          that.makeToast("danger", "Error", err.response.data);
        });
    },
    createNewItem() {
      let that = this
      this.newItem.purchase_order_id = parseInt(this.actualCompra.id)
      let fo_it = this.supplies.find((e) => e.id == this.newItem.supply_id)
      this.newItem.name = fo_it.name
      if (this.newItem.id == null) {
        let elm = this.newItem
        delete elm.id
        axios.post("/stockpicking/" + this.actualCompra.id + "/move", elm)
          .then(function (res) {
            if (res.data.id > 0) {
              that.getMovimiento()
              that.emptyNewItem()
              that.$bvModal.hide("modal-newProduct")
              that.cleanSelectedSupply()
            }
          }).catch(function (err) {
            that.makeToast("danger", "Error", err.response.data)
          })
      } else {
        axios.put("/stockpicking/" + this.actualCompra.id + "/move/" + this.newItem.id, this.newItem)
          .then(function (res) {
            if (res.data.id > 0) {
              that.getMovimiento()
              that.emptyNewItem()
              that.$bvModal.hide("modal-newProduct")
              that.cleanSelectedSupply()
            }
          }).catch(function (err) {
            that.makeToast("danger", "Error", err.response.data)
          })
      }

    },
    makeToast(variant = null, title = "", text = "") {
      if(variant=="success"){
        this.$bvToast.toast(text, {
          title: title,
          variant: variant,
          solid: true,
          appendToast: true,
        });
      }else{
        this.$bvModal.msgBoxOk(text, {
          title: title,
          okVariant: variant,
          centered: true
        })
      }
    },
  },
};
</script>
