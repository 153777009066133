<template>
  <div>
    <Nav></Nav>
    <b-container fluid>
      <b-row>
        <b-col class="text-left">
          <h1>{{ actualCompra.name }}<p>COMPRA ID: {{ actualCompra.id }}</p>
          </h1>
        </b-col>
      </b-row>
      <b-row class="text-left">
        <b-col>
          <b-button :disabled:="lockButtons" @click="$bvModal.show('modal-loadList')" size="sm mr-3" variant="default" class="ml-2 mb-2">Cargar
            Lista de Compra</b-button>
          <b-button :disabled:="lockButtons" @click="downloadExcel('xlsx')" size="sm mr-3" variant="default" class="ml-2 mb-2">Descargar Excel</b-button>
          <b-button :disabled:="lockButtons" @click="downloadExcel('pdf')" size="sm mr-3" variant="default" class="ml-2 mb-2">Descargar PDF</b-button>
          <b-button :disabled:="lockButtons" size="sm mr-3" @click="recalculatePO" variant="default" class="ml-2 mb-2">
            Recalcular
          </b-button>
          <b-button :disabled:="lockButtons" v-show="actualCompra.status != 'cancel'" @click="sendPO('send')" size="sm mr-3" variant="success"
            class="ml-2 mb-2">
            {{ actualCompra.status == "draft" ? "Enviar" : "Reenviar" }}
          </b-button>
          <b-button :disabled:="lockButtons" v-show="actualCompra.status == 'sent'" @click="sendPO('confirm')" size="sm mr-3" variant="success"
            class="ml-2 mb-2">
            Confirmar
          </b-button>
          <b-button :disabled:="lockButtons"  v-show="actualCompra.status == 'confirmed'" @click="sendPO('purchase')" size="sm mr-3"
            variant="success" class="ml-2 mb-2">
            Realizar Compra
          </b-button>
          <b-button  :disabled:="lockButtons" v-show="actualCompra.status == 'purchase'" @click="sendPO('assign')" size="sm mr-3"
            variant="success" class="ml-2 mb-2">
            Asignar
          </b-button>
          <b-button :disabled:="lockButtons"  v-show="actualCompra.status == 'assigned'" @click="sendPO('done')" size="sm mr-3" variant="success"
            class="ml-2 mb-2">
            Terminar
          </b-button>
          <b-button :disabled:="lockButtons"  v-show="actualCompra.status != 'cancel'" @click="sendPO('cancel')" size="sm mr-3" variant="danger"
            class="ml-2 mb-2">
            Cancelar
          </b-button>
          <b-button :disabled:="lockButtons" @click="sendPO('revert')"
            size="sm mr-3" variant="warning" class="ml-2 mb-2">
            Revertir
          </b-button>
        </b-col>
        <b-col>
          <progress-steps :steps="processSteps" :currentStep="actualCompra.status"></progress-steps>
        </b-col>
      </b-row>
      <b-card class="my-2 p-1">
        <b-row>
          <b-col lg="6" md="12">
            <table class="table table-borderless text-left table-sm">
              <tbody>
                <tr>
                  <td><b>Sucursal:</b></td>
                  <td>{{ actualCompra.sucursal.nombre }}</td>
                </tr>
                <tr>
                  <td><b>Proveedor:</b></td>
                  <td>{{ actualCompra.provider.name }}</td>
                </tr>
                <tr>
                  <td><b>Status:</b></td>
                  <td><b-badge :variant="statusBadge(actualCompra.status).variant">{{
                    statusBadge(actualCompra.status).text }}</b-badge></td>
                </tr>
                <tr>
                  <td><b>Creado Por:</b></td>
                  <td>{{ actualCompra.user.name }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
          <b-col lg="6" md="12">
            <table class="table  table-borderless text-left table-sm">
              <tbody>
                <tr>
                  <td><b>Fecha de Creación:</b></td>
                  <td>{{ actualCompra.date_create }}</td>
                </tr>
                <tr>
                  <td><b>Fecha de entrega:</b></td>
                  <td>{{ actualCompra.date_planned }}</td>
                </tr>
                <tr>
                  <td><b>Fecha de Orden:</b></td>
                  <td>{{ actualCompra.date_order }}</td>
                </tr>
                <tr>
                  <td><b>Notas:</b></td>
                  <td>{{ actualCompra.notes }}</td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <table class="table table-sm text-left table-responsive">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Recibido</th>
                  <th>Facturado</th>
                  <th>Unidad</th>
                  <th>Precio Unit.</th>
                  <th>Impuestos Totales</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in actualCompraItems" v-bind:key="item.id" @click="getCompraItem(item.id)">
                  <td>{{ item.id }}</td>
                  <td>{{ item.product.name }}</td>
                  <td>{{ item.product_quantity }}</td>
                  <td>{{ item.qty_received }}</td>
                  <td>{{ item.qty_invoiced }}</td>
                  <td>{{ item.product.unit }}</td>
                  <td>{{ item.price_unit }}</td>
                  <td>{{ item.price_tax }}</td>
                  <td>{{ item.price_total }}</td>
                  <td>
                    <b-icon-pencil-fill @click="getCompraItem(item.id)" variant="success"
                      class="mx-2"></b-icon-pencil-fill>
                    <b-icon-trash-fill @click="deleteCompraItems(item.id)" variant="danger"
                      class="mx-2"></b-icon-trash-fill>
                    <!-- <b-button @click="getCompraItem(item.id)" size="sm" variant="success" class="mr-1 mb-1">
                      <b-icon-pencil-fill></b-icon-pencil-fill>
                    </b-button>
                    <b-button @click="deleteCompraItems(item.id)" size="sm" variant="danger" class="mr-1 mb-1">
                      <b-icon-trash-fill></b-icon-trash-fill>
                    </b-button> -->
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>
        <b-row class="text-left my-2">
          <b-col>
            <b-button @click="cleanSelectedSupply();$bvModal.show('modal-newProduct')" v-shortkey="['ctrl', 'n']"
              @shortkey="cleanSelectedSupply();$bvModal.show('modal-newProduct')" size="sm mr-3">Agregar Producto</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="d-flex flex-row-reverse">
              <div class="flex-grow-1 flex-sm-grow-0">
                <table class="table table-borderless text-left table-sm">
                  <tbody>
                    <tr>
                      <td><b>Subtotal:</b></td>
                      <td>{{ actualCompra.amount_untaxed }}</td>
                    </tr>
                    <tr>
                      <td><b>Impuestos:</b></td>
                      <td>{{ actualCompra.amount_tax }}</td>
                    </tr>
                    <tr>
                      <td><b>Total:</b></td>
                      <td>{{ actualCompra.amount_total }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

          </b-col>
        </b-row>
      </b-card>
    </b-container>
    <b-modal id="modal-newProduct" @shown="focusSupplySelect" @close="emptyNewItem" :hide-footer="true" size="xl"
      title="Agregar Producto">
      <b-row>
        <b-col lg="12" cols="12">
          <b-form-group label="Producto" description="Selecciona el producto a agregar">
            <vu-select @input="selectedProduct" ref="supplySelect" v-model.number="newItem.supply_id"
              :options="supplies" :reduce="(x) => x.id" label="name"></vu-select>
          </b-form-group>
        </b-col>
        <b-col cols="10" md="11" lg="5">
          <b-form-group label="Cantidad" description="Cantidad a Solicitar">
            <b-form-input ref="supplyQty" v-on:keydown.enter="qtyDone" @input="recalculateNewItem" :min="0"
              v-model.number="newItem.product_quantity" type="number" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="2" md="1" lg="1">
          <b-form-group label="UD">
            <h2><b-badge variant="success">{{ selectedSupply.unit }}</b-badge></h2>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12" lg="6">
          <b-form-group label="Precio Unitario" description="Precio por unidad">
            <b-form-input ref="supplyPrice" v-on:keydown.enter="focusTaxes" @input="recalculateNewItem" :min="0"
              v-model.number="newItem.price_unit" type="number" required></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="12" lg="6">
          <b-form-group label="Impuesto Total" description="Impuesto Total">
            <b-form-input ref="supplyTax" v-on:keydown.enter="createNewItem" @input="recalculateNewItem" :min="0"
              v-model.number="newItem.price_tax" type="number" required></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          Total: {{ newItem.price_subtotal }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="my-3">
          <b-button class="mr-3" @click="createNewItem" variant="success">Guardar</b-button>
          <b-button class="mr-3" @click="$bvModal.hide('modal-newProduct'); emptyNewItem();"
            variant="danger">Cancelar</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="modal-loadList" :hide-footer="true" size="xl" title="Cargar lista de compras">
      <b-row>
        <b-col lg="12" cols="12">
          <b-form-group label="Lista de Compras" description="Selecciona la lista de compras que quieres cargar">
            <vu-select v-model.number="selectedShoppingList" :options="shoppingLists" :reduce="(x) => x.id" label="name"
              @input="getShoppingListItems"></vu-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <ul>
            <li v-for="item in shoppingListItemList" v-bind:key="item.id">{{ item.name }}</li>
          </ul>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-button @click="addShoppinglistitemsToPO" variant="success" class="mr-1 mb-1">Agregar a mi orden</b-button>
          <b-button variant="danger" class="mr-1 mb-1">Cancelar</b-button>
        </b-col>
      </b-row>
    </b-modal>

  </div>
</template>
<style>
.table-borderless>tbody>tr>td,
.table-borderless>tbody>tr>th,
.table-borderless>tfoot>tr>td,
.table-borderless>tfoot>tr>th,
.table-borderless>thead>tr>td,
.table-borderless>thead>tr>th {
  border: none;

}
</style>
<script>
import axios from "axios";
import Nav from "../components/Nav.vue";
import ProgressSteps from "../components/ProgressSteps.vue";
export default {
  name: "Compra",
  components: {
    Nav, ProgressSteps
  },

  data() {
    return {
      lockButtons:false,
      processSteps: [
        { name: "draft", label: "Borrador" },
        { name: "sent", label: "Enviado" },
        { name: "confirmed", label: "Confirmado" },
        { name: "purchase", label: "En Compra" },
        { name: "assigned", label: "Asignado" },
        { name: "done", label: "Hecho" },
        { name: "cancel", label: "Cancelado" },
      ],
      addingShoppingList: false,
      selectedShoppingList: null,
      shoppingListItems: [],
      shoppingListItemList: [],
      shoppingLists: [],
      supplies: [],
      actualCompra: {
        id: null,
        company_id: null,
        sucursal_id: null,
        provider_id: null,
        user_id: null,
        name: "",
        status: "",
        amount_untaxed: null,
        amount_tax: null,
        amount_total: null,
        date_create: null,
        date_order: null,
        date_planned: null,
        date_approve: null,
        sucursal: {
          nombre: null
        },
        provider: {
          name: null
        },
        user: {
          name: null
        }
      },
      actualCompraItems: [],
      selectedSupply:{
        unit:null
      },
      newItem: {
        id: null,
        purchase_order_id: null,
        supply_id: null,
        name: "",
        discount: 0,
        price_unit: 0,
        price_subtotal: 0,
        price_tax: 0,
        price_total: 0,
        product_quantity: 1,
        qty_invoiced: 0,
        qty_received: 0,
        qty_to_invoice: 0,
      }
    };
  },

  computed: {

  },
  watch: {},
  mounted() {
    if (this.$route.params.id > 0) {
      this.actualCompra.id = this.$route.params.id;
      this.getCompra()
      this.getCompraItems()
      this.getSupplies()
      this.emptyNewItem()
      this.getShoppingLists()
    }
  },
  created() {

  },
  methods: {
    statusBadge(status) {
      let rtn = { variant: "", text: status }
      switch (status) {
        case "draft":
          rtn.variant = "warning"
          rtn.text = "Borrador"
          break;
        case "sent":
          rtn.variant = "warning"
          rtn.text = "Enviado"
          break;
        case "confirmed":
          rtn.variant = "primary"
          rtn.text = "Confirmado"
          break;
        case "assigned":
          rtn.variant = "primary"
          rtn.text = "Asignado"
          break;
        case "done":
          rtn.variant = "success"
          rtn.text = "Hecho"
          break;

        case "canceled":
          rtn.variant = "danger"
          rtn.text = "Cancelado"
          break;
      }
      return rtn
    },
    focusTaxes() {
      let that = this
      console.log("qtyDone")
      setTimeout(function () {
        that.$refs.supplyTax.$el.focus()
      }, 50)
    },
    focusSupplySelect() {
      if (this.newItem.supply_id == null) {
        this.$refs.supplySelect.$refs.search.focus()
      } else {
        this.$refs.supplyQty.$el.focus()
      }
    },
    cleanSelectedSupply(){
      this.selectedSupply={
        unit:null
      }
    },
    selectedProduct() {
      let that = this
      this.cleanSelectedSupply()
      this.selectedSupply=this.supplies.find(x=>x.id==this.newItem.supply_id)
      setTimeout(function () {
        console.log("focusing")
        that.$refs.supplyQty.$el.focus()
      }, 100)
    },
    qtyDone() {
      let that = this
      console.log("qtyDone")
      setTimeout(function () {
        that.$refs.supplyPrice.$el.focus()
      }, 100)

    },
    sendPO(action) {
      this.$bvModal.msgBoxConfirm('Seguro que quieres cambiar de status', {
        title: 'Confirmacion',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'success',
        okTitle: 'Si',
        cancelTitle: "No",
        headerClass: 'p-2 border-bottom-0',
        footerClass: 'p-2 border-top-0',
        centered: true
      })
        .then(value => {
          if (value) {
            this.lockButtons = true
            let that = this
            axios.post('/purchaseorder/' + this.actualCompra.id + '/' + action)
              .then(function () {
                that.getCompra()
                that.getCompraItems()
                that.makeToast("success", "Enviado", "exito")
                that.lockButtons = false
              })
              .catch(function (error) {
                that.makeToast("danger", "Error", error.response.data);
                that.lockButtons = false
              });
          }

        })
        .catch(err => {
          console.log(err)
        })

    },
    downloadExcel(format) {
      let that = this
      axios.get('/purchaseorder/' + this.actualCompra.id + '?format='+format, {
        "responseType": "blob",
        headers: {
          'Accept': '*/*',
        }
      })
        .then(function (response) {
          console.log(response.headers)
          console.log(response)
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          const contentDisposition = response.headers['content-disposition'];
          console.log(response.headers)
          let filename = 'downloaded_file.'+format; // Fallback filename in case the header is missing
          if (contentDisposition && contentDisposition.includes('filename')) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, ''); // Remove any surrounding quotes
            }
          }
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => that.makeToast("danger", "Error", error.response.data));

    },
    recalculateNewItem() {
      if (this.newItem.product_quantity < 0) {
        this.newItem.product_quantity = 1
      }
      if (this.newItem.price_unit < 0) {
        this.newItem.price_unit = 1
      }
      this.newItem.price_subtotal = this.newItem.product_quantity * this.newItem.price_unit
      this.newItem.price_total = this.newItem.price_subtotal
    },
    emptyNewItem() {
      this.newItem = {
        id: null,
        purchase_order_id: this.actualCompra.id,
        supply_id: null,
        name: "",
        discount: 0,
        price_unit: null,
        price_subtotal: 0,
        price_tax: 0,
        price_total: 0,
        product_quantity: null,
        qty_invoiced: 0,
        qty_received: 0,
        qty_to_invoice: 0,
      }
    },
    getSupplies() {
      let that = this
      axios.get("/supplies")
        .then(function (res) {
          that.supplies = res.data.map(function (el) {
            el.name = el.code + " - " + el.name
            return el
          })
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getShoppingLists() {
      let that = this
      axios.get("/shoppinglists")
        .then(function (res) {
          that.shoppingLists = res.data
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    addShoppinglistitemsToPO() {
      if (this.addingShoppingList) {
        this.makeToast("danger", "Error", "La lista esta siendo cargada, espera un momento")
        return
      }
      this.addingShoppingList = true
      let that = this
      axios.post("/purchaseorder/" + this.actualCompra.id + "/items/addShoppingList/" + this.selectedShoppingList)
        .then(function (res) {
          console.log(res)
          that.$bvModal.hide("modal-loadList")
          that.emptyNewItem()
          that.getCompraItems()
          that.addingShoppingList = false
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getShoppingListItems() {
      let that = this
      axios.get("/shoppinglist/" + this.selectedShoppingList + "/items")
        .then(function (res) {
          that.shoppingListItems = res.data
          let av = that.shoppingListItems.map(function (item) {
            return that.supplies.find((e) => e.id == item.supply_id)
          })
          that.shoppingListItemList = av
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getCompra() {
      let that = this
      axios.get("/purchaseorder/" + this.actualCompra.id)
        .then(function (res) {
          that.actualCompra = res.data
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    recalculatePO() {
      let that = this
      axios.get("/purchaseorder/" + this.actualCompra.id + "/recalculate")
        .then(function () {
          that.getCompra()
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getCompraItems() {
      let that = this
      axios.get("/purchaseorder/" + this.actualCompra.id + "/items")
        .then(function (res) {
          that.actualCompraItems = res.data
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    deleteCompraItems(id) {
      let that = this
      axios.delete("/purchaseorder/" + this.actualCompra.id + "/item/" + id)
        .then(function () {
          that.getCompraItems()
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    getSucursal() {
      let that = this;
      axios
        .get("/restaurant/id")
        .then(function (response) {
          that.actualSucursal = response.data;
          that.getCompras()
        })
        .catch(function (err) {
          console.log(err);
          that.makeToast("danger", "Error", err.response.data);
        });
    },
    getCompraItem(id) {
      let that = this
      axios.get("/purchaseorder/" + this.actualCompra.id + "/item/" + id, this.newItem)
        .then(function (res) {
          that.newItem = res.data
          that.cleanSelectedSupply()
          that.selectedSupply=that.supplies.find(x=>x.id==that.newItem.supply_id)
          that.$bvModal.show('modal-newProduct')
        }).catch(function (err) {
          that.makeToast("danger", "Error", err.response.data)
        })
    },
    createNewItem() {
      let that = this
      this.newItem.purchase_order_id = parseInt(this.actualCompra.id)
      let fo_it = this.supplies.find((e) => e.id == this.newItem.supply_id)
      this.newItem.name = fo_it.name
      if (this.newItem.id == null) {
        let elm = this.newItem
        delete elm.id
        axios.post("/purchaseorder/" + this.actualCompra.id + "/item", elm)
          .then(function (res) {
            if (res.data.id > 0) {
              that.getCompra()
              that.getCompraItems()
              that.emptyNewItem()
              that.$bvModal.hide("modal-newProduct")
            }
          }).catch(function (err) {
            that.makeToast("danger", "Error", err.response.data)
          })
      } else {
        axios.put("/purchaseorder/" + this.actualCompra.id + "/item/" + this.newItem.id, this.newItem)
          .then(function (res) {
            if (res.data.id > 0) {
              that.getCompraItems()
              that.emptyNewItem()
              that.$bvModal.hide("modal-newProduct")
            }
          }).catch(function (err) {
            that.makeToast("danger", "Error", err.response.data)
          })
      }

    },
    makeToast(variant = null, title = "", text = "") {
      if(variant=="success"){
        this.$bvToast.toast(text, {
          title: title,
          variant: variant,
          solid: true,
          appendToast: true,
        });
      }else{
        this.$bvModal.msgBoxOk(text, {
          title: title,
          okVariant: variant,
          centered: true
        })
      }
    },
  },
};
</script>
